/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import Sidebar from './sidebar'
import Topbar from './topbar'
import { Outlet } from 'react-router-dom'
import MobileFooter from './mobileFooter'
import { dataService } from '../../services/dataService'

export default function Layout({isTopbarIsOpen, setIsTopBarIsOpen}) {
    const [notes , setNotes] = useState({});
    const [isBanner, setIsBanner] = useState(true);
    const [ismdBanner, setIsmdBanner] = useState(true);
    const [istextShow , setIstextShow] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const numberOfChars=()=>{
        if(screenWidth > 1130){
            return 200
        }else if(screenWidth>1102){
            return 130
        }else if(screenWidth> 774){
            return 60
        }else if(screenWidth > 418){
            return 50;
        }else{
            return 20;
        }
    }

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = notes?.notification;

    
    const allPs = tempDiv.querySelectorAll('p');  // Get all <p> tags
    const firstP = allPs[0];  // Get the first <p> tag
    const secondPExists = allPs.length > 1;

    const displayContent= notes?.notification?.length> numberOfChars() || secondPExists;
    const notificationContent= istextShow ? notes?.notification : firstP?.outerHTML.substring(0, numberOfChars()-6);
    const buttonText = istextShow ? 'Read less' : 'Read more';


    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };
  
    // Attach event listener when the component renders
    window.addEventListener('resize', handleResize);


    const viewBanner=async()=>{
        const response=await dataService.BannerNotification();
        if(response.error===false){
            setNotes(response.data.data);
            setIsBanner(false)
            setIsmdBanner(false)
        }
        else{
            setIsBanner(true)
            setIsmdBanner(true)
        }
    }

    const toggleFullText= () =>{
        setIstextShow(!istextShow);
    }

    useEffect(()=>{
        viewBanner()
    },[])

    return (
        <div className="relative sm:flex m-0 p-0">
            <Topbar isTopbarIsOpen={isTopbarIsOpen} setIsTopBarIsOpen={setIsTopBarIsOpen} />
            <div className={`lg:w-104 lg:ml-62 md:ml-[200px] md:w-105 w-full ${isTopbarIsOpen ? 'main' : 'main open'}`}>
                <Sidebar />
                <Outlet />
                <MobileFooter/>
            </div>
        </div>
    )
}

